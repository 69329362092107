/* globals ga */
/* eslint new-parens: 0, no-return-assign: 0 */

import 'autotrack/autotrack';

const script = document.getElementById('ga_autotrack');
const trackingIds = script ? JSON.parse(script.dataset.trackingids) : [];

window.ga = window.ga || ((...args) => (ga.q = ga.q || []).push(args));
ga.l = +new Date;

for (let i = 0, len = trackingIds.length; i < len; i++) {

    const trackingId = trackingIds[i];
    const trackerName = `tracker${i}`;
    ga('create', trackingId, 'auto', trackerName);

    /**
     * Event tracker - makes setting events easier.
     * @example <button ga-on="click" ga-event-category="Video" ga-event-action="play" ga-event-label="Fall Campaign" ga-event-value="42"></button>
     */
    ga(`${trackerName}.require`, 'eventTracker', {
        events: [
            'click',
        ],
    });

    /**
     * Clean URL Tracker - adds a trailing slash to the end of all urls.
     */
    ga(`${trackerName}.require`, 'cleanUrlTracker', {
        trailingSlash: 'add',
    });

    /**
     * Outbound Link Tracker - creates an 'Outbound Link' event for each outbound link click.
     */
    ga(`${trackerName}.require`, 'outboundLinkTracker', {
        events: [
            'click',
            'contextmenu',
        ],
    });

    /**
     * Use the navigator.sendBeacon method, if available in the browser.
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon}
     */
    ga(`${trackerName}.set`, 'transport', 'beacon');

    /**
     * Add custom dimensions.
     * In order to do this, you need to set up a custom dimension on your GA property in Google Analytics.
     *  1. In Google Analytics, go to admin, select your property and select Custom Definitions > Custom Dimensions.
     *  2. Click 'New Custom Dimension'.
     *  3. Enter the name and set the scope to the necessary setting (User for loginsid tracking). Make sure 'active' is checked.
     *  4. Check the dimension name in the ga('set') Javascript snippet. You will need that to set it below.
     */
    // const customDimensions = {
    //     loginsid: 'dimension1',
    // };
    // ga(`${trackerName}.set`, customDimensions.loginsid, loginsid);

    /**
     * Send the pageview
     */
    ga(`${trackerName}.send`, 'pageview');

}
